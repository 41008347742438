<template>
  <div class="main">
    <back></back>
    <div class="title">采购单详情</div>
    <div class="top">
      <div class="item">进货单编号：{{ from.purchaseNum }}</div>
      <div class="item">店铺名称：{{ from.shopName }}</div>
      <div class="item">供应商名称：{{ from.supplierName }}</div>
      <div class="item">联系人：{{ from.contactMan }}</div>
      <div class="item">手机号码：{{ from.contactTel }}</div>
      <div class="item">下单时间：{{ from.orderTime }}</div>
      <div class="item" v-if="from.shippingStatus == 1 || from.signStatus == 1">发货时间：{{ from.deliveryTime }}</div>
      <div class="item" v-if="from.signStatus == 1">确认收货时间：{{ from.goodsTime }}</div>
      <div class="item" v-if="from.purchaseStatus == 1">接单时间：{{ from.acceptanceTime }}</div>
      <div class="item">
        <span>接单状态：</span>
        <!-- <span :class="from.purchaseStatus == 0 ? 'red' : ''">{{ from.purchaseStatus == 0 ? "待接单" : from.purchaseStatus == 1 ? "已接单" : "已取消" }}</span> -->
        <span v-if="from.purchaseStatus == 0" style="color: red">待接单</span>
        <span v-if="from.purchaseStatus == 1">已接单</span>
        <span v-if="from.purchaseStatus == 2">已取消</span>
      </div>
      <div class="item">
        <span>发货状态：</span>
        <!-- <span>{{ from.shippingStatus == 0 ? "待发货" : from.shippingStatus == 1 ? "已发货" : "" }}</span> -->
        <span v-if="from.shippingStatus == 0">待发货</span>
        <span v-if="from.shippingStatus == 1">已发货</span>
        <span v-if="from.shippingStatus == 2">已取消</span>
      </div>
      <div class="item">
        <span>签收状态：</span>
        <!-- <span>{{ from.signStatus == 0 ? "待签收" : from.signStatus == 1 ? "已签收" : "" }}</span> -->
        <span v-if="from.signStatus == 0">待签收</span>
        <span v-if="from.signStatus == 1">已签收</span>
        <span v-if="from.signStatus == 2">已取消</span>
      </div>
      <div class="item" v-if="from.shippingStatus == 1 || from.signStatus == 1">送货人姓名：{{ from.deliveryPerson }}</div>
      <div class="item" v-if="from.shippingStatus == 1 || from.signStatus == 1">送货人电话：{{ from.deliveryPhone }}</div>
      <!-- <div class="item" v-if="from.purchaseStatus==2||from.purchaseStatus==3">送货人车牌号：{{from. carNum}}</div> -->
    </div>
    <div class="globle_table">
      <el-table :data="tableData" style="width: 100%" height="440">
        <el-table-column prop="number" label="序号" width="100">
          <template slot-scope="scope">
            {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
          </template>
        </el-table-column>
        <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="100"></el-table-column>
        <el-table-column prop="productName" label="商品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="specs" label="规格"></el-table-column>
        <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip></el-table-column>
        <el-table-column prop="enterBox" label="入箱数"></el-table-column>
        <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip></el-table-column>
        <el-table-column prop="brand" label="品牌" show-overflow-tooltip></el-table-column>
        <el-table-column min-width="120" prop="expirationDay" label="保质期（天）"></el-table-column>
        <el-table-column min-width="120" prop="supplyPrice" label="价格（元）"></el-table-column>
        <el-table-column prop="productBox" label="采购箱数"></el-table-column>
        <el-table-column prop="deliveryBox" label="发货箱数" v-if="from.shippingStatus == 1 || from.signStatus == 1"></el-table-column>
        <el-table-column prop="receiveBox" label="收货箱数" width="180" align="center" v-if="from.purchaseStatus == 1 && from.shippingStatus == 1 && from.signStatus == 0">
          <template slot-scope="scope">
            <el-input v-model.number="scope.row.receiveBox" placeholder="请输入" @input="saveData(scope.row)"></el-input>
            <!-- <el-input v-model.number="scope.row.deliveryBox" placeholder="请输入" @input="saveData(scope.row)"></el-input> -->
          </template>
        </el-table-column>
        <el-table-column prop="receiveBox" label="收货箱数" align="center" v-if="from.signStatus == 1"></el-table-column>
      </el-table>
      <div style="text-align: center; margin-top: 10px"><el-button type="primary" @click="shop_confirm" v-if="from.purchaseStatus == 1 && from.shippingStatus == 1 && from.signStatus == 0">确认收货</el-button></div>
    </div>

    <el-dialog title="上传收货的订单" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="图片上传" prop="upload">
          <el-upload v-model="ruleForm.fileUrl" ref="upload" :action="apiBaseUrl" name="multipartFile" :headers="apiHeaders" :limit="1" :file-list="importFiles" accept=".jpg,.png" :on-success="handleSuccess" :before-upload="beforeUpload">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import back from "../../../../../05/src/components/back.vue"
export default {
  components: { back },
  data() {
    return {
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      shopConfirmReceiptDto: [],
      purchaseId: "",
      from: {},
      dialogVisible: false,
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.uploadFile,
      apiHeaders: {
        token: this.$store.state.token,
      },
      importFiles: [],
      ruleForm: {
        fileUrl: "",
      },
      rules: {
        fileUrl: [{ required: true, message: "请上传", trigger: "change" }],
      },
    }
  },
  created() {
    //接受跨页面的传参
    this.purchaseId = this.$route.query.purchaseId
    this.getPurchase_cart_info()
  },
  methods: {
    handleSuccess(res) {
      if (res.code == 100) {
        this.$message.success(res.desc)
        this.ruleForm.fileId = res.result.fileId
        this.ruleForm.fileUrl = res.result.fileUrl
      } else {
        this.$message.error(res.desc)
        this.importFiles = []
      }
    },
    beforeUpload(file) {
      // console.log(file);
      const suffixArr = ["jpg", "png"]
      if (!suffixArr.includes(file.name.split(".").pop())) {
        this.$message.error(`只能上传"jpg""png"格式图片`)
        return false
      }
    },
    // 列表
    getPurchase_cart_info() {
      this.$axios
        .get(this.$api.purchase_cart_info, {
          params: {
            purchaseId: this.purchaseId,
          },
        })
        .then(res => {
          // this.tableData = res.data.result.purchaseProductVoList;
          this.tableData = res.data.result.purchaseProductVoList.map(element => {
            if (element.receiveBox == 0) {
              return {
                ...element,
                receiveBox: element.deliveryBox,
              }
            } else {
              return {
                ...element,
              }
            }
          })

          // console.log("列表数据", this.tableData)
          // this.tableData数组中加入receiveCount字段然后返回完整数组
          // this.tableData.forEach(item => {
          //   item.receiveBox = item.deliveryBox;
          // });
          this.shopConfirmReceiptDto = this.tableData.map(item => {
            return {
              productId: item.productId,
              receiveBox: item.receiveBox,
              enterBox: item.enterBox,
              purchaseProductId:item.purchaseProductId
            }
          })
          this.from = res.data.result
        })
        .catch(() => {})
    },
    saveData(row) {
      // console.log(row);
      let data = Object.assign({}, row)
      const value = data.receiveBox
      if (value !== null && (value < 1 || value > 999999)) {
        this.$message.error("请输入数字,不能小于1且不大于9999999")
        data.receiveBox = null // 清空输入值
      }
      // 数据去重
      const existing = this.tableData.find(item => item.id === row.id && item.receiveBox === row.receiveBox)
      if (!existing) {
        // 如果没有找到重复项，则更新或添加数据
        this.updateOrAddRow(row)
      }
      let list = this.tableData.map(item => {
        return {
          productId: item.productId,
          receiveBox: item.receiveBox,
          enterBox: item.enterBox,
          purchaseProductId:item.purchaseProductId

        }
      })
      this.shopConfirmReceiptDto = list
    },
    updateOrAddRow(row) {
      const index = this.tableData.findIndex(item => item.id === row.id)
      if (index !== -1) {
        // 更新现有数据
        this.$set(this.tableData, index, row)
      } else {
        // 添加新数据
        this.tableData.push(row)
      }
    },
    // 关闭对话框
    onCancel() {
      this.resetForm()
      this.dialogVisible = false
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {}
      this.$refs.upload.clearFiles()
      this.$refs.ruleForm.resetFields()
      this.ruleForm.fileUrl = ""
      this.importFiles = []
      // console.log(this.ruleForm);
    },
    submitForm() {
      this.dialogVisible = false
      this.$axios
        .post(this.$api.shop_confirm, {
          imageUrl: this.ruleForm.fileUrl,
          purchaseNum: this.from.purchaseNum,
          supplyOrderItems: this.shopConfirmReceiptDto || this.tableData,
        })
        .then(res => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc)
            //提交成功后，清空上传图片的路径
            this.resetForm()
            this.importFiles = []
            // 路由返回上一层
            this.$router.go(-1)
          }
        })
        .catch(() => {})
    },
    shop_confirm() {
      // console.log(this.tableData);

      this.$message.warning("可以上传收货的订单")
      setTimeout(() => {
        this.dialogVisible = true
      }, 800)
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  padding: 30px;
  box-sizing: border-box;

  ::v-deep .el-upload-list__item:first-child {
    width: 40% !important;
  }

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    /* 允许子元素换行 */

    .item {
      font-size: 16px;
      flex: 0 0 19%;
      margin-right: 10px;
      margin-bottom: 30px;
      /* 添加底部边距，用于区分行 */
      box-sizing: border-box;
      /* 确保margin不会影响元素的实际宽度 */
    }
  }

  .red {
    color: red;
  }

  .grey {
    color: grey;
  }
}
</style>
